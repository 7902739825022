export const END_POINTS = {
    ubigeo: 'departamento',
    province: 'provincia',
    admin: {
      companies: 'empresa',
      errors: 'error',
    },
    oauth: {
        login: 'login', // en proceso
        logout: 'logout',
        signup: 'signup',
        active: 'auth/active',
        create: 'auth/create',
        find: 'find',
        reset: 'auth/reset',
        user: 'usuario',
    },
    setup: {
        module: 'mudulo',
        menu: 'menu',
        rol: 'rol',
        users: 'usuario',
        enterprise: 'empresa',
        user: 'details',
    },
    transport: {
      drivers: 'conductor',
      vehicles: 'vehiculo',
      transports: 'transportista',
      guideRemissions: 'guia-remision',
    },
    warehouse: {
      measureUnit: 'unidad-medida',
      productType: 'tipo-producto',
      office: 'oficina',
      warehouses: 'almacen',
      article: 'articulo', // en uso
      providers: 'proveedor',
      category1: 'categoria', // en uso
      category2: 'categoria2',
      category3: 'categoria3',
      category4: 'categoria4',
      category5: 'categoria5',
      area: 'area',
      movements: 'movimiento-almacen',
      transationWarehouse: 'transaccion-almacen',
      distrbutors: 'distribuidor'
    },
    accountant: {
      accountingEntries: 'asiento-contable', // en proceso
      detractions: 'tipo-detraccion',
      perceptions: 'tipo-percepcion',
      voucherTypes: 'tipo-comprobante', // en uso
      vouchers: 'comprobante',
      paymentTypes: 'tipo-pago',
      paymentModes: 'modo-pago',
      expensesTypes: 'tipo-gasto',
      moneyTypes: 'tipo-moneda',
      period: 'periodo', // terminado
      series: 'serie',
      creditNoteMotive: 'nota-credito',
    },
    purchase: {
      orders: 'orden-compra',
      purchases: 'compra', // usando
        importation: 'importacion', // usando
        requeriments: 'requerimiento', // usando
        recipes: 'recetas', // usando
        technicalRecord: 'expedientetecnico', // usando
        quotes: 'cotizacion', // usando
    },
    sales: {
      sale: 'venta', // usando
      order: 'pedido',
      bank: 'banco',
      pricesListDetail: 'lista-precio-detalle',
      promoCombo: 'promocion-combo',
      promoHamper: 'promocion-canasta',
      promo: 'promocion',
      salesman: 'vendedor',
      exchangeRate: 'tipo-cambio',
      distributions: 'reparto',
      proforma: 'proforma',
      // distributions: 'nota-credito'
    },
    customers: {
      customer: 'cliente', // en uso
      prices: 'lista-precio',
      businessType: 'tipo-negocio',
      routes: 'ruta',
      weekVisits: 'semana-visita',
      dayVisits: 'dia-visita',
    },
    cash: {
      cash: 'caja',
      cashier: 'cajero',
      cashTransaction: 'caja-transaccion',
      cashMovement: 'caja-movimiento',
      payroll: 'planilla',
    },

    /*****---------- */
    userModules: 'user-modules',
    validTokensOauth: 'valid-tokens-oauth',
    orders: 'messages',
};

