import { Injectable, Inject } from '@angular/core';
import { TOKEN_OAUTH_STORE, OauthStore } from '../../utils';

@Injectable({ providedIn: 'root' })
export class AuthStoreService { // se esta utilizando este servicio para el local storage
  constructor(
    @Inject(TOKEN_OAUTH_STORE) protected xStoreOauth: OauthStore,
  ) { }

  public setAccessToken(accessToken: string) {
    localStorage.setItem(this.xStoreOauth.accessToken, accessToken);
  }
  public getAccessToken() {
    return localStorage.getItem(this.xStoreOauth.accessToken);
  }

  public setAuthorizationCode(authorizationCode: string) {
    localStorage.setItem(this.xStoreOauth.authorizationCode, authorizationCode);
  }
  public getAuthorizationCode() {
    return localStorage.getItem(this.xStoreOauth.authorizationCode);
  }

  public setUserData(userData: any) {
    localStorage.setItem(this.xStoreOauth.username, JSON.stringify(userData));
  }
  public getUserData() {
    return JSON.parse(localStorage.getItem(this.xStoreOauth.username));
  }

  public setEnterpriseData(enterData: any) {
    localStorage.setItem(this.xStoreOauth.enterpriseData, JSON.stringify(enterData));
  }
  public getEnterpriseData() {
    return JSON.parse(localStorage.getItem(this.xStoreOauth.enterpriseData));
  }

  public setWarehouseActive(activeData: any) {
    localStorage.setItem(this.xStoreOauth.activeWarehouse, JSON.stringify(activeData));
  }
  public getWarehouseActive() {
    return JSON.parse(localStorage.getItem(this.xStoreOauth.activeWarehouse));
  }

  public setCasherSerieActive(activeData: any) {
    localStorage.setItem(this.xStoreOauth.activeSerie, JSON.stringify(activeData));
  }
  public getCasherSerieActive() {
    return JSON.parse(localStorage.getItem(this.xStoreOauth.activeSerie));
  }

  public clearAll() {
    localStorage.clear();
  }
}
