import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResponse, END_POINTS } from '../../utils';
import { tap, shareReplay } from 'rxjs/operators';
import { AuthStoreService } from './auth-store.service';
import { EnterpriseService } from '../setup/enterprise.service';

@Injectable({ providedIn: 'root' })
export class OauthService {

  constructor(
    private http: HttpClient,
    private authStoreService: AuthStoreService,
      private enterpriseService: EnterpriseService,
  ) { }

  public authenticate(credentials: any): Observable<IResponse> {
    return this.http.post<IResponse>(END_POINTS.oauth.login, credentials)
      .pipe(tap(this.setSession.bind(this)), shareReplay());
  }

  private setSession(response) {
    // const expireAt = 120;
    if (response) {
      const authorizationCode = response && response.access_token || null;
      this.authStoreService.setAuthorizationCode(authorizationCode);
      this.getDataLocal();
    } else {
      this.notAutorized(response.message);
    }
  }

  private notAutorized(error) {
    this.authStoreService.clearAll();
  }
  private async getDataLocal() {
    let e = await this.enterpriseService.getAll$().subscribe(response => {
          const enterData = response && response.data || [];
          this.authStoreService.setEnterpriseData(enterData);
          e.unsubscribe();
      });
}
}
