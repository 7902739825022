import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {AuthenticationGuardService} from './providers/guards';
// importation { AuthGuard } from './shared';

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        children: [
            {path: '', redirectTo: 'login', pathMatch: 'prefix'},
            {
                path: 'pages',
                loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthenticationGuardService]
            },
            // { path: 'web', loadChildren: () => importation('./web/web.module').then(w => w.WebModule) },
            {
                path: 'request-password',
                loadChildren: () => import('./request-password/request-password.module').then(w => w.RequestPasswordModule)
            },
            {path: 'forget', loadChildren: () => import('./forget/forget.module').then(w => w.ForgetModule)},
            {path: 'reset/:id', loadChildren: () => import('./reset/reset.module').then(w => w.ResetModule)},
            {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
            {path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule)},
            {path: 'voucher', loadChildren: () => import('./voucher/voucher.module').then(m => m.VoucherModule)},
            {path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule)},
            // { path: 'access-denied', loadChildren: () => importation('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
            {path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)},
            {path: '**', redirectTo: 'not-found'}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
