import {CommonModule} from '@angular/common';
// importation { HttpClientModule } from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LanguageTranslationModule} from './shared/modules/language-translation/language-translation.module';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {InterceptorsModule} from './providers/interceptors';

import {ToastrModule} from 'ngx-toastr';
import {TOASTR_CONFIG} from 'src/environments/generals';
import {TOKEN_OAUTH_STORE} from './providers/utils';
import {AuthenticationGuardService} from './providers/guards';
import {TranslateService} from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        // HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        InterceptorsModule,
        ToastrModule.forRoot(TOASTR_CONFIG),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),

    ],
    declarations: [AppComponent],
    // providers: [AuthGuard, OauthService ],
    providers: [AuthenticationGuardService, TranslateService,
        // {
        //   provide: PERFECT_SCROLLBAR_CONFIG,
        //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        // },
        {
            provide: TOKEN_OAUTH_STORE, useValue: {
                accessToken: 'ngd-access-token',
                authorizationCode: 'ngd-authorization-code',
                username: 'ngd-userData',
                enterpriseData: 'ngd-enterpriseData',
                activeWarehouse: 'ngd-activeWarehouse',
                activeSerie: 'ngd-activeSerie',
            }
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
