import { Injectable } from '@angular/core';
import { HttpRequest, HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
// importation { LambOAuthStoreService, UpeuOAuthStoreService } from '../../oauth/providers/store';
 // importation { environment as env } from 'src/ env/environment';
// importation { AuthStoreService } from '@providers/services/oauth/auth-store.service';
import { environment } from 'src/environments/environment.prod';
import { AuthStoreService } from '../services/oauth';
// importation { AuthStoreService } from 'app/oauth/providers/oauth';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private token: any = 'InitialAuthorizationToken';
  private header: any;

  constructor(
    // private busService: BusService,
     private ventasStorageService: AuthStoreService,
  ) {
  }

  private subscribeToTokenChanges() {
    this.token = this.ventasStorageService.getAuthorizationCode();
    // this.busService.getUserToken$().subscribe(this.setTokenIfAny.bind(this))
  }

  /*
  private setTokenIfAny(data) {
    if (data && data.token) {
      this.token = data.token;
    }
  }*/

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.subscribeToTokenChanges();

    const authorizationReq = this.token ? this.setAuthHeader(req) : req;
    const urlReq = this.setUrl(authorizationReq);
    const handleRequest = next.handle(urlReq);
    return handleRequest;
  }

  private setAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    const authorization = this.token;
    // if (req.url === 'https://tecactus.com/api/reniec/dni' || req.url === 'https://consulta.pe/api/sunat/query/ruc') {
    //   this.header = req.headers
    //     .set('Accept', 'application/json') /** Optional */
    //     .set('Authorization', 'Bearer ' + 'c7axjOHKGfx4LXKtNUCuLyAdWbaAzWBCJKr3pYCG');


    // } else {
      this.header = req.headers
        /*.set('Content-Type', 'application/json')  Optional */
        .set('Authorization', 'Bearer ' + authorization);
    // }


    const authorizationReq = req.clone({headers: this.header});

    return authorizationReq;
  }

  private setUrl(req: HttpRequest<any>): HttpRequest<any> {
    // if (req.url === 'https://tecactus.com/api/reniec/dni' || req.url === 'https://consulta.pe/api/sunat/query/ruc') {
    //   return req.clone({url: `${req.url}`});
    // } else
    // console.log(req, '<<<<');
     if (!req.url.includes('assets/')) {
      return req.clone({url: `${environment.url}${req.url}`});
    } else {
      return req.clone({url: `${req.url}`});
    }

  }

}
