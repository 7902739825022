export const TOASTR_CONFIG = {
    // positionClass: 'toast-bottom-right',
    positionClass: 'toast-top-right',
    timeOut: 5000,
    // positionClass: 'toast-bottom-center',
};

export const MESSAGES = {
    btnSave: 'Guardar',
    btnCancel: 'Cancelar',
    btnRetrive: 'Limpiar',
    btnNew: 'Nuevo',
    btnNewa: 'Nueva',
    btnEdit: 'Actualizar',
    btnViewDetail: 'Detalle',
    btnDelete: 'Eliminar',
    btnActived: 'Activado',
    btnDeactived: 'Desactivado',
    btnClose: 'Cerrado',
    btnOpen: 'Abierto',
    btnConfirm: 'Confirmar',
    btnReturn: 'Regresar',
    btnAcept: 'Aceptar',
    btnExchange: 'Asignar',
    btnShow: 'Listar',
    btnDuplicate: 'Duplicar',
    btnVisualize: 'Visualizar',
    btnImport: 'Importar',
    btnExport: 'Exportar',
    btnAdd: 'Agregar',
    btnDownload: 'Descargar',
    btnDevolution: 'Devolver',
    btnNullify: 'Anular',
    btnApprove: 'Aprobar',
    btnLiquidate: 'Liquidar',
    btnRemissionGuide: 'Guia Remisión',
    btnSend: 'Enviar',
    btnPrint: 'Imprimir',
    btnCreditNote: 'Nota de Crédito',
    btnArowDown: 'Devolver',
    btnArowUp: 'Regresar',

    btnSendSunat: 'Enviar Sunat',
    btnDownloadXML: 'Descargar XML',
    btnDownloadCDR: 'Descargar CDR',
    btnXls: 'Importar XLS',
    btnChangeStatus: 'Cambiar Estado',

    btnGenerate: 'Generar',
    btnPayment: 'Pagar',
    btnDuoble: 'Duplicar',
};

export const abcForms = {
    btnDuoble: {
        label: MESSAGES.btnDuoble,
        icon: 'fa fa-clone', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnChangeStatus: {
        label: MESSAGES.btnChangeStatus,
        icon: 'fa fa-toggle-on', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnPayment: {
        label: MESSAGES.btnPayment,
        icon: 'fa fa-check', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnGenerate: {
        label: MESSAGES.btnGenerate,
        icon: 'fa fa-cogs', /** FontAewsome */
        class: 'btn-outline-info', /** primary, secondary, ligth (bootstrap) */
    },
    btnXls: {
        label: MESSAGES.btnXls,
        icon: 'fa fa-file-excel-o', /** FontAewsome */
        class: 'btn-outline-success', /** primary, secondary, ligth (bootstrap) */
    },
    btnSendSunat: {
        label: MESSAGES.btnSendSunat,
        icon: 'assets/icons/sunat.jpg', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnDownloadXML: {
        label: MESSAGES.btnDownloadXML,
        icon: 'fa fa-download', /** FontAewsome */
        class: 'btn-outline-dark', /** primary, secondary, ligth (bootstrap) */
    },
    btnDownloadCDR: {
        label: MESSAGES.btnDownloadCDR,
        icon: 'fa fa-download', /** FontAewsome */
        class: 'btn-outline-dark', /** primary, secondary, ligth (bootstrap) */
    },
    btnArowDown: {
        label: MESSAGES.btnCreditNote,
        icon: 'fa fa-arrow-circle-down', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnArowUp: {
        label: MESSAGES.btnArowUp,
        icon: 'fa fa-arrow-circle-up', /** FontAewsome */
        class: 'btn-outline-danger', /** primary, secondary, ligth (bootstrap) */
    },
    btnCreditNote: {
        label: MESSAGES.btnCreditNote,
        icon: 'fa fa-sticky-note', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnPrint: {
        label: MESSAGES.btnPrint,
        icon: 'fa fa-print', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnSend: {
        label: MESSAGES.btnSend,
        icon: 'fa fa-paper-plane', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnRemissionGuide: {
        label: MESSAGES.btnRemissionGuide,
        icon: 'fa fa-window-restore', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnLiquidate: {
        label: MESSAGES.btnLiquidate,
        icon: 'fa fa-location-arrow', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnApprove: {
        label: MESSAGES.btnApprove,
        icon: 'fas fa-check', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnDevolution: {
        label: MESSAGES.btnDevolution,
        icon: 'fa fa-arrow-circle-left', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnNullify: {
        label: MESSAGES.btnNullify,
        icon: 'fa fa-minus-circle', /** FontAewsome */
        class: 'btn-outline-danger', /** primary, secondary, ligth (bootstrap) */
    },
    btnDownload: {
        label: MESSAGES.btnDownload,
        icon: 'fa fa-download', /** FontAewsome */
        class: 'btn-outline-dark', /** primary, secondary, ligth (bootstrap) */
    },
    btnAdd: {
        label: MESSAGES.btnAdd,
        icon: 'fa fa-plus', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnSave: {
        label: MESSAGES.btnSave,
        icon: 'fa fa-check', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnEdit: {
        label: MESSAGES.btnEdit,
        icon: 'fa fa-edit', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnCancel: {
        label: MESSAGES.btnCancel,
        icon: 'fa fa-times', /** FontAewsome */
        class: 'btn-outline-secondary', /** primary, secondary, ligth (bootstrap) */
    },
    btnNew: {
        label: MESSAGES.btnNew,
        labela: MESSAGES.btnNewa,
        icon: 'fa fa-plus-circle', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnReturn: {
        label: MESSAGES.btnReturn,
        icon: 'fas fa-reply', /** FontAewsome */
        class: 'btn-outline-secondary', /** primary, secondary, ligth (bootstrap) */
    },
    btnRetrive: {
        label: MESSAGES.btnRetrive,
        icon: 'fa fa-eraser', /** FontAewsome */
        class: 'btn-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnViewDetail: {
        label: MESSAGES.btnViewDetail,
        icon: 'fa fa-eye', /** FontAewsome */
        class: 'btn-outline-primary', /** primary, secondary, ligth (bootstrap) */
    },
    btnDelete: {
        label: MESSAGES.btnDelete,
        icon: 'fa fa-trash', /** FontAewsome */
        class: 'btn-outline-danger', /** primary, secondary, ligth (bootstrap) */
    },
    btnActived: {
        label: MESSAGES.btnActived,
        icon: 'fa fa-thumbs-up', /** FontAewsome */
        class: 'btn-light', /** primary, secondary, ligth (bootstrap) */
    },
    btnDeactived: {
        label: MESSAGES.btnDeactived,
        icon: 'fa fa-thumbs-down', /** FontAewsome */
        class: 'btn-light', /** primary, secondary, ligth (bootstrap) */
    },
    btnClose: {
        label: MESSAGES.btnClose,
        icon: 'fa fa-lock', /** FontAewsome */
        class: 'btn-danger', /** primary, secondary, ligth (bootstrap) */
    },
    btnOpen: {
        label: MESSAGES.btnOpen,
        icon: 'fa fa-unlock', /** FontAewsome */
        class: 'btn-success', /** primary, secondary, ligth (bootstrap) */
    },
    btnConfirm: {
        label: MESSAGES.btnConfirm,
        icon: 'fa fa-check-square',
        class: 'btn-primary',
    },
    btnCheck: {
        label: MESSAGES.btnSave,
        icon: 'ion-checkmark-round',
        class: 'btn-primary', /**btn-success */
    },
    btnClosed: {
        label: MESSAGES.btnCancel,
        icon: 'ion-close-round',
        class: 'btn-outline-secondary',
    },
    btnEditIcon: {
        label: MESSAGES.btnEdit,
        icon: 'fa fa-edit',
        class: 'btn-light',
    },
    btnPlus: {
        label: MESSAGES.btnNew,
        icon: 'fa fa-plus-square-o',
        class: 'btn-primary',
    },
    btnExchange: {
        icon: 'fa fa-exchange',
        label: MESSAGES.btnExchange,
        class: 'btn-outline-dark',
    },
    btnShow: {
        label: MESSAGES.btnShow,
        icon: 'fa fa-shopping-cart',
        class: 'btn-primary',
    },
    btnDuplicate: {
        label: MESSAGES.btnDuplicate,
        icon: 'fa fa-files-o',
        class: 'btn-primary',
    },
    btnVisualize: {
        label: MESSAGES.btnVisualize,
        icon: 'fa fa-filter',
        class: 'btn-primary',
    },
    btnImport: {
        label: MESSAGES.btnImport,
        icon: 'fa fa-cloud-upload',
        class: 'btn-outline-primary',
    },
    btnExport: {
        label: MESSAGES.btnExport,
        icon: 'fa fa-download',
        class: 'btn-outline-primary',
    },

};
export const OperationType = [
    {id: 10, value: 'Grabado-Operacíon oneroza [10]'},
    // {id: 11, value: '[Gratuita] Gravado-Retiro por premio [11]'},
    // {id: 12, value: '[Gratuita] Gravado-Retiro donación [12]'},
    // {id: 13, value: '[Gratuita] Gravado-Retiro [13]'},
    // {id: 14, value: '[Gratuita] Gravado-Retiro por publicidad [14]'},
    // {id: 15, value: '[Gratuita] Gravado-Bonificaciones[15]'},
    // {id: 16, value: '[Gratuita] Gravado-Retiro por entrega de trabajadores[16]'},
    {id: 20, value: 'Exonerado- Operación Onerosa[20]'},
    {id: 30, value: 'Inafecto-Operación Onerosa[30]'},
    // {id: 31, value: '[Gratuita] Inafecto-Retiro por Bonificación[31]'},
    // {id: 32, value: '[Gratuita] Inafecto-Retiro[32]'},
    // {id: 33, value: '[Gratuita] Inafecto-Retiro por Muestras Médicas[33]'},
    // {id: 34, value: '[Gratuita] Inafecto-Retiro por Convenio Colectivo[34]'},
    // {id: 35, value: '[Gratuita] Inafecto-Retiro por premio[35]'},
    // {id: 36, value: '[Gratuita] Inafecto-Retiro por publicidad[36]'},
    // {id: 40, value: 'Exportación[40]'},
];


export const documentTypesIdentity = [
    {id: 1, text: 'DNI'},
    //{id: 6, text: 'RUC'},
    {id: 7, text: 'Passaporte'},
    {id: 4, text: 'Carné Extrangería'},
    {id: 0, text: 'Otros'},
];
export const devolutionMotive = [
    {id: '01', text: 'ANULACION DE LA OPERACION'},
    {id: '02', text: 'ANULACION POR ERROR EN EL RUC'},
    {id: '03', text: 'CORRECCION POR ERROR EN LA DESCRIPCION'},
    {id: '04', text: 'DESCUENTO GLOBAL'},
    {id: '05', text: 'DESCUENTO POR ITEM'},
    {id: '06', text: 'DEVOLUCION TOTAL'},
    {id: '07', text: 'DEVOLUCION POR ITEM'},
    {id: '08', text: 'BONIFICACION'},
    {id: '09', text: 'DISMINUCION EN EL VALOR'},
    {id: '10', text: 'OTROA CONCEPTOS'},
];

export const voucherTypes = [
    {id: '01', text: 'FACTURA'},
    {id: '03', text: 'BOLETA DE VENTA'},
    {id: '07', text: 'NOTA DE CRÉDITO'},
    {id: '08', text: 'NOTA DE DÉBITO'},
    {id: '99', text: 'NOTA DE VENTA'},
    {id: '09', text: 'GUIA DE REMISIÓN'},
 ];

 export const years = [
    {id: 2019, text: 2019},
    {id: 2020, text: 2020},
    {id: 2021, text: 2021},
    {id: 2022, text: 2022},
    {id: 2023, text: 2023},
    {id: 2024, text: 2024},
    {id: 2025, text: 2025},
    {id: 2026, text: 2026},
    {id: 2027, text: 2027},
];

export const months = [
    {id: '01', text: 'Enero'},
    {id: '02', text: 'Febrero'},
    {id: '03', text: 'Marzo'},
    {id: '04', text: 'Abril'},
    {id: '05', text: 'Mayo'},
    {id: '05', text: 'Junio'},
    {id: '07', text: 'Julio'},
    {id: '08', text: 'Agosto'},
    {id: '09', text: 'Setiembre'},
    {id: '10', text: 'Octubre'},
    {id: '11', text: 'Noviembre'},
    {id: '12', text: 'Diciembre'},
];
