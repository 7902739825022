import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { END_POINTS, EntityDataService, IResponse } from '../../utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnterpriseService extends EntityDataService<IResponse> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, END_POINTS.setup.enterprise);
    }

    public getEnterpriseFilter$(data?): Observable<IResponse> {
        return this.httpClient.get<IResponse>(`${this.endPoint}-filtro`, {params: data});
    }
    public postEnterpriseCertificate$(data: object): Observable<any> {
        return this.httpClient.post(`${this.endPoint}-certificado`, data); //, {responseType: 'blob'}
    }
}
